@use 'styles/mixins.scss';

.title {
  @include mixins.title;
}

.button {
  @include mixins.btn-mb;
}

.sitesTable {
  margin-top: 36px;
}

.modalTitle {
  font-size: 20px;
  margin: 30px 0 35px;
}

.modalBtnBlock {
  width: 210px;
  display: flex;
  justify-content: space-between;
}

@mixin mainFont {
  font-family: PT Sans, Arial, sans-serif;
}

@mixin secondaryFont {
  font-family: 'PT Sans Caption', Arial, sans-serif;
}

@mixin endEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin errorMessage {
  width: 100%;
  height: 18px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--color-text-red);
}

@mixin btn-mb {
  margin: 0 0 33px;
}

@mixin mb-24 {
  margin-bottom: 12px;
}

@mixin input {
  @include mainFont;
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
  border: 1px solid var(--color-input-border);
  color: var( --color-text);
  padding: 0 10px;
  letter-spacing: 0.015em;

  &::placeholder {
    color: var(--color-grey-90);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--color-input-focus);
  }
}

@mixin invalidInput {
  border: 1px solid var(--color-input-invalid);
}

@mixin title {
  font-family: Rubik, Arial, serif;
  font-size: 20px;
  margin: 33px 0 20px;
}

@mixin standardForm {
  .form {
    width: 310px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;

    .label {
      display: inline-block;
      width: 100%;
      margin-bottom: 12px;
      margin-top: 36px;
      font-size: 18px;
      text-align: left;

      &:first-child {
        margin-top: 0;
      }
    }

    .buttons {
      display: flex;
      justify-content: left;
      width: 100%;
      margin-top: 32px;

      & button {
        margin-right: 20px;
      }
    }
  }
}

@mixin tooltip {
  font-size: 14px;
  font-weight: 400;
  padding: 16px;
  border-radius: 12px;
  background-color: var(--color-menuItem-hover);
}
